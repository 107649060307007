import BaseSelect from '@/components/common/base-select/base-select.vue'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '供应商',
      prop: 'gysName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['enterpriseName', 'enterpriseName']
      },
      tag: BaseSelect
    },
    {
      label: '项目公司',
      prop: 'xmgsName',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.fold
    },
    {
      label: '锁定状态',
      prop: 'lockFlag',
      attrs: {
        options: content.lockOptions,
        placeholder: '请选择',
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.fold,
      tag: BaseSelect
    }
  ]
}
// 表格form
export const dataTable = (content) => {
  return [
    {
      type: 'selection',
      width: '50px',
      selectable: content.checkboxT,
      isHidden: !content.inspectPower('b-batchUnlock')
    },
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    // {
    //   label: 'EAS标识码',
    //   prop: 'contractId',
    //   minWidth: '150px'
    // },
    {
      label: '供应商',
      prop: 'gysName',
      minWidth: '150px'
    },
    {
      label: '项目公司',
      prop: 'xmgsName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同名称',
      prop: 'contractName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同类别',
      prop: 'contractType',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同金额',
      prop: 'contractAmount',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.contractAmount ? row.contractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '收款人名称',
      prop: 'account',
      minWidth: '100px',
      align: 'left'

    },
    {
      label: '收款人开户银行',
      prop: 'bankName',
      minWidth: '150px',
      align: 'left'
    },
    {
      label: '收款人银行账号',
      prop: 'accountNo',
      minWidth: '150px',
      align: 'left'
    },
    {
      label: '回函文件',
      prop: 'fileName',
      minWidth: '150px',
      HiddenOverflow: true
    },
    {
      label: '锁定状态',
      prop: 'lockFlag',
      minWidth: '80px',
      formatter: row => {
        const [obj] = content.lockOptions.filter(item => item.dictId === row.lockFlag)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      minWidth: '150px'
    }
  ]
}
