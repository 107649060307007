<template>
  <div class="contract-lock">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
   <!-- 批量解锁 -->
    <div class="batch" v-if="inspectPower('b-batchUnlock')"><base-button label="批量解锁" @click="batchUnlock" class="batchBtn"></base-button></div>
    <!-- 表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
        selectable:checkboxT
      }"
       :selectedRows.sync="selectedRows"
      :queryParas="queryParas"
      :dataSource.sync="tableData"
      ref="tableData"
      :api="api"
      :getApi="'getPage'"
      :loadCount="loadCount"
      :webPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <!-- 表内回函文件 -->
      <template slot="fileName" slot-scope="scope">
        <template v-if="scope.row.fileName">
          <div class="file-content" ref='fileName'>
            <!-- 文件名 -->
            <el-tooltip  effect="dark" :content="scope.row.fileName" placement="top-end" :disabled="disabledar[scope.$index]" :enterable="false">
            <span  class="file-name  hiddentip" @click="downloadFile(scope.row.fileId)"  ref="hiddentip" >{{scope.row.fileName}}</span>
            </el-tooltip>
          </div>
        </template>
      </template>
      <!-- 表内操作 -->
      <template slot="action" slot-scope="scope">
        <icon-button  content="加锁" v-if="scope.row.lockFlag!=='1' && inspectPower('b-lock')" icon="iconfont iconjiesuo" @click="viewlock(scope.row)"/>
        <icon-button  content="履历"  v-if="scope.row.lockFlag !=='0'" icon="iconfont iconlvli" @click="viewResume(scope.row)"/>
        <icon-button  content="解锁"  v-if="scope.row.lockFlag ==='1' && inspectPower('b-batchUnlock')" icon="iconfont iconjiasuo" @click="viewUnlock(scope.row)"/>
      </template>
    </base-table>
    <!-- 加锁弹出框 -->
    <lock-dialog :visible.sync="visibleLock" :contractId='contractId '></lock-dialog>
    <!-- 履历弹出框 -->
    <resume-dialog  :visible.sync="visibleResume" :contractId='contractId '></resume-dialog>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { lockAPi } from '@/api/assetApi'
import { supplierApi } from '@/api/companyApi'
import { fileAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import LockDialog from './components/lock-dialog.vue'
import ResumeDialog from './components/resume-dialog.vue'
import { getDictLists } from '@/filters/fromDict'

export default {
  name: 'accountManage',
  components: { BaseForm, BaseTable, IconButton, BaseButton, LockDialog, ResumeDialog },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      gysData: [],
      visibleDetail: false, // 详情显示
      visibleLock: false, // 详情显示
      visibleResume: false, // 详情显示
      detailInfo: {},
      fold: false,
      finResultId: '',
      businessId: '',
      disabledar: [],
      contractId: '', // 加锁文件id
      selectedRows: [] // 选中的数据
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return lockAPi
    },
    // 加锁解锁状态
    lockOptions () {
      return getDictLists('LOCK_FLAG')
    }
  },
  created () {
    this.muenList = this.$store.state.sys.menusList
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  methods: {
    // 条件筛选不能选择的
    checkboxT (row, index) {
      if (row.lockFlag !== '1') {
        return false
      } else {
        return true
      }
    },
    // 履历弹窗
    viewResume (data) {
      this.contractId = data.keyId
      this.visibleResume = true
    },
    // 加锁解锁按钮
    addLockFn (contractId) {
      lockAPi.lockDetail({ contractId: contractId }).then(res => {
        if (res.code) {
          this.contractId = contractId
          this.visibleLock = true
        } else {
          this.warning('该业务已被他人锁定')
          this.handleFilter()
        }
      })
    },
    // 加锁弹窗
    viewlock (data) {
      // if (data.lockFlag === '1') {
      //   this.$stateConfirm({ title: '提示', message: '该合同已加锁，请确认是否再次加锁？', show: true, type: 'warning' })
      //     .then(() => {
      //       this.addLockFn(data.keyId)
      //     }).catch(() => {})
      // } else {
      //   this.addLockFn(data.keyId)
      // }
      this.addLockFn(data.keyId)
    },
    // 解锁
    viewUnlock (row) {
      // if (row.keyId) {
      //   this.$stateConfirm({ title: '提示', message: row.lockFlag === '1' ? '确认进行解锁操作？' : '该合同已解锁，请确认是否再次解锁？', show: true, type: 'warning' })
      //     .then(() => {
      //       this.lockFn({ contractIds: row.keyId })
      //     }).catch(() => {})
      // }
      this.$stateConfirm({ title: '提示', message: '确认解锁？', show: true, type: 'warning' }).then(res => {
        this.lockFn({ contractIds: row.keyId })
      }).catch()
    },
    // 批量解锁
    batchUnlock () {
      const unlockArr = this.selectedRows.map(item => item.keyId)
      if (unlockArr.length <= 0) {
        this.warning('请先选择需要解锁的文件')
        return
      }
      this.$stateConfirm({ title: '提示', message: '确认进行解锁操作？', show: true, type: 'warning' })
        .then(() => {
          this.lockFn({ contractIds: unlockArr.join(',') })
        }).catch(() => {})
    },
    // 解锁方法
    lockFn (data) {
      lockAPi.batchUnlock(data).then(res => {
        if (res.data) {
          this.success('解锁成功')
          this.handleFilter()
        }
      })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10
      }
    },
    UploadFile (param, row) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.success) {
          this.accountFileEdit(res.data, row)
        }
      })
    },
    // 回函文件上传
    accountFileEdit (file, row, type) {
      const params = {
        businessNo: row.businessNo,
        fileId: file.keyId ? file.keyId : '',
        fileName: file.fileName ? file.fileName : '',
        keyId: row.keyId,
        lockFlag: row.lockFlag
      }
      if (type === 'delete') {
        this.$stateConfirm({ title: '提示', message: '此操作将删除回函文件是否继续？', show: true, type: 'warning' }).then(() => {
          this.api.accountFileEdit(params).then(res => {
            if (res.success) {
              this.success('删除成功')
              this.handleFilter()
            }
          })
        }).catch(() => {})
      } else {
        this.api.accountFileEdit(params).then(res => {
          if (res.success) {
            this.success('上传成功')
            this.handleFilter()
          }
        })
      }
    },
    // 回函文件下载
    downloadFile (id) {
      fileAPi.download({ keyId: id }).then(res => {
        downFile(res)
      })
    },
    // 查看详情
    viewDetail (data) {
      this.detailInfo = data
      this.visibleDetail = true
      this.businessId = data.businessId
      this.finResultId = data.finResultId
    },
    // 查看是否是有权限
    inspectPower (data) {
      let pass = false
      const obj = this.muenList.filter(item => {
        if (item.opAuthorityDTOList) {
          return item
        }
      })
      obj.forEach(ele => {
        ele.opAuthorityDTOList.some(code => {
          if (code.operationCode === data && code.authStatus === 0) {
            pass = true
          }
        })
      })
      return pass
    }
  }
}
</script>
<style lang="scss" src="./index.scss" scoped/>
